interface ImagePlaceholderAction {
  (node: HTMLElement): void;
}

const actionCollectionFactory = () => {
  const actions = new Map<string, ImagePlaceholderAction>();

  return {
    has: (actionName: string) => actions.has(actionName),
    register: (name: string, action: ImagePlaceholderAction) => actions.set(name, action),
    call: (name: string, node: HTMLElement): void => {
      if (actions.has(name) && typeof actions.get(name) === 'function') {
        const callback = actions.get(name);
        callback?.(node);
      }
    },
  };
};

const { register, call } = actionCollectionFactory();

register('replace', (node: HTMLImageElement) => {
  if (!node.dataset.livedataPlaceholder) {
    return;
  }

  node.src = node.dataset.livedataPlaceholder;
});

// eslint-disable-next-line jest/require-hook
register('playerPagesHeroPlaceholder', (node: HTMLImageElement) => {
  if (!node.dataset.livedataPlaceholder) {
    return;
  }

  node.src = node.dataset.livedataPlaceholder;
});

const managePlaceholderImageNode = (node: HTMLImageElement) => {
  const onErrorCb = () => {
    call(node.dataset.livedataPlaceholderAction ?? 'replace', node);
  };

  if (node.complete && node.naturalHeight === 0 && node.naturalWidth === 0) {
    onErrorCb();
  }
  node.addEventListener('error', onErrorCb);
};

const managePlaceholderNode = (node: Element) => {};

const manageImagePlaceholders = () => {
  const imagesWithPlaceholder = document.querySelectorAll('[data-livedata-placeholder]');

  imagesWithPlaceholder.forEach((domNode) => {
    if (domNode.tagName.toLowerCase() === 'img') {
      return managePlaceholderImageNode(domNode as HTMLImageElement);
    }

    managePlaceholderNode(domNode);
  });
};

export { register };
export default manageImagePlaceholders;
