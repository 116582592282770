export const setImgPlaceholder = () => {
  const images: NodeListOf<Element> = document.querySelectorAll('img[data-placeholder]');
  images.forEach((image: Element) => {
    image.addEventListener('error', (evt: Event) => {
      const img: HTMLImageElement = evt.target as HTMLImageElement;
      const placeholder: string | null = img.getAttribute('data-placeholder');
      if (placeholder && placeholder.length > 0) {
        // eslint-disable-next-line no-console
        console.warn(`[livedata-data-cards] Missing image '${img.src}', replaced with placeholder '${placeholder}'`);
        img.src = placeholder;
        img.removeAttribute('data-placeholder');
      }
    });
  });
};
