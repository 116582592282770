import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { IMatchChangeHpTemplate } from '@sky-it-livedata/livedata-sports-design-system';

import { IComponentModel } from '../../typings/common-types';
import HtmlWrapper from '../html-wrapper/html-wrapper';

const MatchChangeHpWrapper = React.lazy(() => import('./match-change-hp'));

export interface IDynamicMatchChangeHpWrapper extends IComponentModel<IMatchChangeHpTemplate> {
  live?: boolean;
}

const DynamicMatchChangeHp: React.FC<IDynamicMatchChangeHpWrapper> = ({
  /* istanbul ignore next: default value */
  children = null,
  /* istanbul ignore next: default value */
  live = false,
  /* istanbul ignore next: default value */
  model = {},
  /* istanbul ignore next: default value */
  query = {},
}) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <MatchChangeHpWrapper
      live={live}
      initialModel={validateModel(model) as IMatchChangeHpTemplate}
      query={validateModel(query)}
    />
  </Suspense>
);

DynamicMatchChangeHp.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  live: PropTypes.bool,
  model: PropTypes.any,
  query: PropTypes.object,
};

DynamicMatchChangeHp.defaultProps = {
  children: null,
  model: {} as IMatchChangeHpTemplate,
  query: {},
  live: false,
};

export default DynamicMatchChangeHp;
