import { ICardDriverRanking } from '@sky-it-livedata/livedata-sports-design-system';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { validateModel } from '@sky-it-livedata-libraries/livedata-commons';

import HtmlWrapper from '../html-wrapper/html-wrapper';
import { IComponentModel } from '../../typings/common-types';

const DataCardMotorsportsDriverRankingWrapper = React.lazy(() => import('./data-card-motorsports-driver-ranking'));

export interface IDynamicDataCardMotorsportsDriverRanking extends IComponentModel<ICardDriverRanking> {
  live?: boolean;
}

const DynamicDataCardMotorsportDriverRanking: React.FC<IDynamicDataCardMotorsportsDriverRanking> = ({
  /* istanbul ignore next: default value */ model = {},
  /* istanbul ignore next: default value */ query = {},
  /* istanbul ignore next: default value */ children = null,
  /* istanbul ignore next: default value */ live = false,
}) => (
  <Suspense fallback={<HtmlWrapper html={children} />}>
    <DataCardMotorsportsDriverRankingWrapper
      live={live}
      initialModel={validateModel(model) as ICardDriverRanking}
      query={validateModel(query)}
    />
  </Suspense>
);

DynamicDataCardMotorsportDriverRanking.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  live: PropTypes.bool,
  model: PropTypes.any,
  query: PropTypes.object,
};

/**
 * YOU need to specify default props to be derived from web-component attributes
 */
DynamicDataCardMotorsportDriverRanking.defaultProps = {
  children: null,
  model: {} as ICardDriverRanking,
  live: false,
  query: {},
};

export default DynamicDataCardMotorsportDriverRanking;
