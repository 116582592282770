/* eslint-disable jest/require-hook */

import {
  matchCardWidget,
  matchChangeHpWidget,
  // matchFollowWidget,
  dataCardFootballRankingWidget,
  dataCardFootballCalendarWidget,
  dataCardMotorsportsDriverRanking,
  dataCardMotorsportsCalendar,
  dataCardMotorsportsGpResults,
  dataCardMotorsportsTeamRanking,
} from '@sky-it-livedata/livedata-data-cards-widgets';
import { defineWebComponents } from '@sky-it-livedata-libraries/livedata-commons-client';

/* Import Dynamic Components */
import DynamicCardMotorsportsTeamsRanking from './components/card-motorsports-teams-ranking/dynamic-card-motorsports-teams-ranking';
import DynamicDataCardMotorsportCalendar from './components/data-card-motorsport-calendar/dynamic-data-card-motorsport-calendar';
import DynamicDataCardFootballRanking from './components/data-card-football-ranking/dynamic-data-card-football-ranking';
import DynamicMatchCardDetail from './components/match-card/dynamic-match-card';
import DynamicMatchChangeHp from './components/match-change-hp/dynamic-match-change-hp';
// import DynamicMatchFollow from './components/match-follow/dynamic-match-follow';
import DynamicDataCardFootballCalendar from './components/data-card-football-calendar/dynamic-data-card-football-calendar';
import { setImgPlaceholder } from './utils/image-placeholder';
import DynamicDataCardMotorsportsDriverRanking from './components/data-card-motorsports-driver-ranking/dynamic-data-card-motorsports-driver-ranking';
import DynamicDataCardMotorsportsResults from './components/data-card-motorsport-results/dynamic-data-card-motorsport-results';
import manageImagePlaceholders from './utils/placeholder-images';

const webComponentsMap = new Map();
webComponentsMap.set(dataCardMotorsportsTeamRanking.tag, DynamicCardMotorsportsTeamsRanking);
webComponentsMap.set(matchCardWidget.tag, DynamicMatchCardDetail);
webComponentsMap.set(matchChangeHpWidget.tag, DynamicMatchChangeHp);
// webComponentsMap.set(matchFollowWidget.tag, DynamicMatchFollow);
webComponentsMap.set(dataCardFootballCalendarWidget.tag, DynamicDataCardFootballCalendar);
webComponentsMap.set(dataCardMotorsportsDriverRanking.tag, DynamicDataCardMotorsportsDriverRanking);
webComponentsMap.set(dataCardMotorsportsCalendar.tag, DynamicDataCardMotorsportCalendar);
webComponentsMap.set(dataCardFootballRankingWidget.tag, DynamicDataCardFootballRanking);
webComponentsMap.set(dataCardMotorsportsGpResults.tag, DynamicDataCardMotorsportsResults);
console.info('defineWebComponents', { webComponentsMap }); // eslint-disable-line no-console

defineWebComponents(webComponentsMap);

manageImagePlaceholders();

document.addEventListener('DOMContentLoaded', () => {
  setImgPlaceholder();
});
